import React from 'react';
import styles from './Gallery.module.css';

const gallery = ({images, showPicture}) => {

    let pictures = null;
    if (images) {
        pictures = images.map((image, index) => (
            <div key={index} className={styles.picture}>
                <img src={image.src} alt={image.alt} onClick={e => showPicture(e)} />
            </div>
        ))
    }
    return (
        
        <div className={styles.gallery}>
            {pictures}
        </div>
    )
}

export default gallery;