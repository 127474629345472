import React from 'react';
import styles from './Header.module.css';

const header = (props) => {
    return (
        <header className={styles.header}>
            <h1>Football Manager to moje życie</h1>
            {props.children}
        </header>
    )
}
export default header;