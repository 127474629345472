import axios from 'axios';
import React, { Component } from 'react';
import Link from '../Link/Link';
import terms from '../../Terms/Regulamin.pdf';
import styles from './Form.module.css';

class Form extends Component {

    state = {
        isValid: false,
        name: '',
        isNameAfterValidation: false,
        isNameValid: false,
        email: '',
        isEmailAfterValidation: false,
        isEmailValid: false,
        orderNumber: '',
        isOrderNumberAfterValidation: false,
        isOrderNumberValid: false,
        story: '',
        isStoryAfterValidation: false,
        isStoryValid: false,
        isTermsChecked: false,
        isTermsAfterValidation: false,
        mailSent: false,
        error: null
    }

    nameHandler = (e) => {
        this.setState({name: e.target.value, isNameAfterValidation: false});
    }

    emailHandler = (e) => {
        this.setState({email: e.target.value, isEmailAfterValidation: false});
    }

    orderNumberHandler = (e) => {
        this.setState({orderNumber: e.target.value, isOrderNumberAfterValidation: false});
    }

    storyHandler = (e) => {
        let story = e.target.value;
        if (story.length >= 2000) {
            story = story.slice(0, 2000);
        }
        this.setState({story: story, isStoryAfterValidation: false});
    }

    checkboxHandler = (e) => {
        this.setState({isTermsChecked: e.target.checked, isTermsAfterValidation: false});
    }

    validateNameInput = () => {
        const patt = /[A-Za-z]+\s[A-Za-z]+/;
        const name = this.state.name;
        return patt.test(name);
    }

    validateEmailInput = () => {
        const patt = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
        const email = this.state.email;
        return patt.test(email);
    }

    validateOrderNumberInput = () => {
        const patt = /\w+/;
        const orderNumber = this.state.orderNumber;
        return patt.test(orderNumber);
    }

    validateStoryInput = () => {
        const patt = /[A-Za-z]+/;
        const story = this.state.story;
        return patt.test(story);
    }

    submitHandler = (e) => {
        e.preventDefault();
        this.validateInputs();
    }

    validateInputs = () => {
        this.setState({
            isNameAfterValidation: true,
            isNameValid: this.validateNameInput(),
            isEmailAfterValidation: true,
            isEmailValid: this.validateEmailInput(),
            isOrderNumberAfterValidation: true,
            isOrderNumberValid: this.validateOrderNumberInput(),
            isStoryAfterValidation: true,
            isStoryValid: this.validateStoryInput(),
            isTermsAfterValidation: true
        }, this.checkInputs);
    }

    checkInputs = () => {
        this.setState({
            isValid: this.state.isNameValid && this.state.isEmailValid && this.state.isOrderNumberValid && this.state.isStoryValid && this.state.isTermsChecked
        }, this.submitFormData);
    }

    submitFormData = () => {
        if (this.state.isValid) {
            console.log("Wysyłamy")
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API}`,
                headers: { 'content-type': 'application/json' },
                data: this.state
            })
            .then(result => {
                if (result.data.sent) {
                    this.setState({
                        mailSent: result.data.sent
                    });
                    this.setState({ error: false });
                } else {
                    this.setState({ error: true });
                }
            })
            .catch(error => this.setState({ error: error.message }));
        } else {
            console.log("Nie wysyłamy");
        }
    }

    content = null;

    render() {
        if (this.state.mailSent) this.content = <div className={styles.message}>Dziękujemy za przesłanie historii</div>;
        if (this.state.error) this.content = <div className={styles.message}>Ups! Coś nie zadziałało. Spróbuj jeszcze raz</div>;

        return (
            <form action="#" className={styles.form}>
                {this.content}
                <h2>Twoja historia w książce o Football Managerze!</h2>
                <input type="text" onChange={e => this.nameHandler(e)} placeholder="Imię i nazwisko" value={this.state.name} style={this.state.isNameValid || !this.state.isNameAfterValidation ? {border: '2px solid transparent'} : {border: '2px solid red'}}/>
                <input type="email" onChange={e => this.emailHandler(e)} placeholder="Email" value={this.state.email} style={this.state.isEmailValid || !this.state.isEmailAfterValidation ? {border: '2px solid transparent'} : {border: '2px solid red'}} />
                <input type="text" onChange={e => this.orderNumberHandler(e)} placeholder="Numer zamówienia LaBotiga.pl" value={this.state.orderNumber} style={this.state.isOrderNumberValid || !this.state.isOrderNumberAfterValidation ? {border: '2px solid transparent'} : {border: '2px solid red'}} />
                <div className={styles.textarea}>
                    <textarea onChange={e => this.storyHandler(e)} placeholder="Podziel się swoimi wspomnieniami (max. 2000 znaków)" value={this.state.story} style={this.state.isStoryValid || !this.state.isStoryAfterValidation ? {border: '2px solid transparent'} : {border: '2px solid red'}} />
                    <label>{this.state.story.length}/2000</label>
                </div>
                <div className={styles.checkbox} style={this.state.isTermsChecked || !this.state.isTermsAfterValidation ? {border: '2px solid transparent'} : {border: '2px solid red'}}>
                    <input type="checkbox" onChange={e => this.checkboxHandler(e)} value={this.state.isTermsChecked} />
                    <span>Oświadczam, że zapoznałem się z treścią <Link type="link" to={terms}>regulaminu</Link> i akceptuję jego treść.</span>
                </div>
                <input type="submit" onClick={(e) => this.submitHandler(e)} value="Wyślij nam swoją historię!" />
            </form>
        )
    };
}

export default Form;