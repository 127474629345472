import React from 'react';
import Button from '../Button/Button';
import Link from '../Link/Link';
import styles from './BottomMenu.module.css';

const menu = (props) => {

    let style = null;
    if (props.isDisplayed) {
        style = {transform: 'translateY(0)'}
    }
    return (
        <nav className={styles.menu} style={style}>
            <ul>
                <li>
                    <Button type="main" onClick={props.clicked}>Kup książkę</Button>
                </li>
                <li>
                    <Link type="button" to="https://www.labotiga.pl/340-ksiazka-sportowa-football-manager?utm_source=landing&utm_medium=btn-footballmanager&utm_campaign=footballmanager">Kup u wydawcy</Link>
                </li>
                <li>
                    <Link type="button" to="https://www.labotiga.pl/340-ksiazka-sportowa-football-manager?utm_source=landing&utm_medium=btn-footballmanager&utm_campaign=footballmanager">Kup pakiet</Link>
                </li>
                <li>
                    <Link type="button" to="https://www.empik.com/football-manager-to-moje-zycie-historia-najpiekniejszej-obsesji-macintosh-ian-millar-kenny-white-neil,p1236607115,ksiazka-p">Kup na Empik.com</Link>
                </li>
            </ul>
        </nav>
    );
}

export default menu;