import React from 'react';
import styles from './Button.module.css';

const button = (props) => {
    const classes = [styles.button];
    if (props.type) {
        classes.push(styles[`button--${props.type}`]);
    }
    if (props.active) {
        classes.push(styles.active)
    }
    if (props.special) {
        classes.push(styles.special)
    }
    return (
        <button className={classes.join(" ")} onClick={props.onClick}>
            {props.children}
        </button>
    );
}

export default button;