import React from 'react';
import Button from '../Button/Button';
import styles from './MainMenu.module.css';

const menu = (props) => {
    const menuElements = [
        'O książce',
        'FM-owe historie',
        'Twoja historia',
        'Pakiet'    
    ];

    const menuList = menuElements.map((el, index) => {
        if (el === props.page) {
            return <li key={index}><Button active type="main" onClick={(e) => props.clicked(e.target)}>{el}</Button></li>
        } else if (el === 'Pakiet') {
            return <li key={index}><Button special type="main" onClick={(e) => props.clicked(e.target)}>{el}</Button></li>
        } else {
            return <li key={index}><Button type="main" onClick={(e) => props.clicked(e.target)}>{el}</Button></li>
        }
    })

    return (
        <nav className={styles.menu}>
            <ul>
                {menuList}
            </ul>
        </nav>
    )
}

export default menu;