import React from 'react';
import styles from './Date.module.css';

const date = () => {
    let date = new Date();
    let dayName;
    switch(date.getDay()) {
        case 0:
            dayName = "Niedziela";
            break;
        case 1:
            dayName = "Poniedziałek";
            break;
        case 2:
            dayName = "Wtorek";
            break;
        case 3:
            dayName = "Środa";
            break;
        case 4:
            dayName = "Czwartek";
            break;
        case 5:
            dayName = "Piątek";
            break;
        case 6:
            dayName = "Sobota";
            break;
        default:
            dayName = "";
    }
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return (
        <div className={styles.date}>
            <span>{dayName}</span>
            <span>{day}.{month}.{year}</span>
        </div>
    )
}

export default date;