import React from 'react';
import styles from './FullImage.module.css';

const fullImage = ({image, hidePicture}) => {

    return (
        <div className={styles.image}>
            <span onClick={hidePicture}>&#10005;</span>
            <img src={image.src} alt={image.alt} onClick={hidePicture} />
        </div>
    )
}

export default fullImage;