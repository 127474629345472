import React from 'react';
import styles from './Link.module.css';

const link = (props) => {

    return (
        <a className={styles[props.type]} href={props.to} target="blank">
            {props.children}
        </a>
    );
}

export default link;