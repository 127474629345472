import React, { Component } from 'react';
import Layout from './Containers/Layout/Layout';
import Sidebar from './Components/SidebarMenu/SidebarMenu';
import Header from './Components/Header/Header';
import Menu from './Components/MainMenu/MainMenu';
import Main from './Components/Main/Main'
import BottomMenu from './Components/BottomMenu/BottomMenu';
import FullImage from './Components/FullImage/FullImage';
import './App.css';

class App extends Component {

    state = {
        activePage: 'O książce',
        pages: [
            'O książce',
            'Fragmenty',
            'FM-owe historie',
            'Twoja historia',
            'Pakiet',
            'CM 01/02'
        ],
        isBottomMenuShown: false,
        isSidebarMenuShown: false,
        pictureToDisplay: null
    }

    nextPage = () => {
        const pageIndex = this.state.pages.indexOf(this.state.activePage);
        let nextPageIndex = pageIndex + 1;
        if (nextPageIndex >= this.state.pages.length) {
            nextPageIndex = 0;
        }
        const nextPage = this.state.pages[nextPageIndex]
        this.setState({activePage: nextPage});
    }

    prevPage = () => {
        const pageIndex = this.state.pages.indexOf(this.state.activePage);
        let prevPageIndex = pageIndex - 1;
        if (prevPageIndex < 0) {
            prevPageIndex = this.state.pages.length - 1;
        }
        const prevPage = this.state.pages[prevPageIndex]
        this.setState({activePage: prevPage});
    }

    displayForm = () => {
        this.setState({activePage: 'Check'});
    }

    changeActivePage = (page) => {
        this.setState({activePage: page});
    }

    getClickedButtonName = (target) => {
        const page = target.innerHTML;
        this.setState({activePage: page, isSidebarMenuShown: false});
    }

    toggleBottomMenu = () => {
        this.setState((state) => ({isBottomMenuShown: !state.isBottomMenuShown, isSidebarMenuShown: false}));
    }

    toggleSidebarMenu = () => {
        this.setState((state) => ({isBottomMenuShown: false, isSidebarMenuShown: !state.isSidebarMenuShown}));
    }

    showPicture = (e) => {
        this.setState({pictureToDisplay: {src: e.target.src, alt: e.target.alt}})
    }

    hidePicture = () => {
        this.setState({pictureToDisplay: null})
    }

    render() {
        return (
            <div className="App">
                <Layout>
                    <Sidebar clicked={this.getClickedButtonName} next={this.nextPage} prev={this.prevPage} toggleMenu={this.toggleSidebarMenu} isDisplayed={this.state.isSidebarMenuShown} page={this.state.activePage} />
                    <Header>
                        <Menu clicked={this.getClickedButtonName} page={this.state.activePage} />
                    </Header>
                    <Main page={this.state.activePage} displayForm={this.displayForm} showPicture={this.showPicture} />
                    <BottomMenu clicked={this.toggleBottomMenu} isDisplayed={this.state.isBottomMenuShown} />
                </Layout>
                {this.state.pictureToDisplay ? <FullImage image={this.state.pictureToDisplay} hidePicture={this.hidePicture} /> : null}
            </div>
        );
}
}

export default App;
