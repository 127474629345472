import React, { Component } from 'react';
import Story from '../Story/Story';
import styles from './Stories.module.css';

class stories extends Component {

    state = {
        activeStory: 0
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.stories !== this.props.stories) {
            this.resetActive();
        }
    }
    
    resetActive = () => {
        this.setState({activeStory: 0});
    }

    setActive = (target) => {
        const activeStory = Number(target.closest("li").id.split('-')[1]);
        this.setState({activeStory: activeStory});
    }

    render() {
        const index = this.props.stories[this.state.activeStory] ? this.state.activeStory : 0;
        const titleLists = this.props.stories.map(story => (
            <li id={story.id}
                key={story.id}
                className={this.state.activeStory === Number(story.id.split('-')[1]) ? styles.active : ''} 
                onClick={(e) => this.setActive(e.target)}>
                    <span>{story.author}</span>
                    <span>{story.title}</span>
            </li>
        ))

        return (
            <div className={styles.stories}>
                <ul>
                    {titleLists}
                </ul>
                <Story story={this.props.stories[index]} showPicture={this.props.showPicture} />
            </div>
        );
    }
}

export default stories;