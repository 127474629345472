import React, { Component } from 'react';
import Gallery from '../Gallery/Gallery';
import styles from './Story.module.css';

class Story extends Component {

    componentDidUpdate = (prevProps) => {
        if (prevProps.story !== this.props.story) this.scrollToTop();
    }

    scrollToTop = () => {
        const container = document.querySelector(`.${styles.text}`);
        container.scrollTop = 0;
    }
    
    render() {

        return (
            <div className={styles.story}>
                <h2>{this.props.story.title}</h2>
                <div className={styles.text} id="story" style={{top: '0px'}}>
                    {this.props.story.text.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
                    <Gallery images={this.props.story.images} showPicture={this.props.showPicture} />
                </div>
            </div>
        )
    }
}

export default Story;