import React, { Component } from 'react';
import styles from './Check.module.css';

class Check extends Component {

    state = {
        showContent: false,
        isValid: false,
        isOrderNumberAfterValidation: false,
        orderNumber: '',
        winningOrderNumbers: [
            'SCGQLYJNB',
            'VXMKBEVLX',
            'GDHMVOVZZ',
            'OXHHHTERS',
            'UZNYOEHJZ',
            'FGDINSQKP',
            'EWUUEPTBA',
            'BTWCZQDLE',
            'CPZNFQUAC',
            'EMNYEWUYI',
            'IXFCOXGOC',
            'GVLHPZHAL',
            'IYHEQVZBN',
            'WTFTIMVEH',
            'JSQCGQUZR',
            'YTVENHJFN',
            'JDUYGZCQA',
            '10100484622822',
            '10100479862141'
        ]
    }

    orderNumberHandler = (e) => {
        this.setState({orderNumber: e.target.value, isOrderNumberAfterValidation: false});
    }

    validateOrderNumberInput = () => {
        const patt = /\w+/;
        const orderNumber = this.state.orderNumber;
        return patt.test(orderNumber);
    }

    submitHandler = (e) => {
        e.preventDefault();
        const isValid = this.validateOrderNumberInput();
        this.setState({isValid: isValid, isOrderNumberAfterValidation: true, showContent: isValid});
    }

    content = null;

    render() {

        if (this.state.showContent) {
            if (this.state.winningOrderNumbers.includes(this.state.orderNumber)) {
                this.content = <div className={styles.message}>Gratulujemy! Twoja historia została zawarta w książce!</div>;
            } else {
                this.content = <div className={styles.message}>Niestety, w książce zabraknie Twojej historii.</div>;
            }
        }

        return (
            <form action="#" className={styles.form}>
                <h2>Sprawdź, czy Twoja historia trafiła do książki</h2>
                <input type="text" onChange={e => this.orderNumberHandler(e)} placeholder="Numer zamówienia LaBotiga.pl" value={this.state.orderNumber} style={this.state.isValid || !this.state.isOrderNumberAfterValidation ? {border: '2px solid transparent'} : {border: '2px solid red'}} />
                <input type="submit" onClick={(e) => this.submitHandler(e)} value="Sprawdź!" />
                {this.content}
            </form>
        )
    };
}

export default Check;