import React from 'react';
import Stories from '../Stories/Stories';
import FullText from '../FullText/FullText';
import Form from '../Form/Form';
import Check from '../Check/Check';
import styles from './Main.module.css';
import texts from '../../Texts/Texts';

const main = (props) => {
    let page = null;
    switch(props.page) {
        case "FM-owe historie":
            page = <Stories stories={texts.stories} />
            break;
        case "Twoja historia":
            page = <FullText text={texts.aboutAction} displayForm={props.displayForm} showPicture={props.showPicture} />
            break;
        case "Formularz":
            page = <Form />
            break;
        case "Check":
            page = <Check />
            break;
        case "O książce":
            page = <FullText text={texts.about} showPicture={props.showPicture} />
            break;
        case "Fragmenty":
            page = <Stories stories={texts.fragments} />
            break;
        case "CM 01/02":
            page = <FullText text={texts.aboutGame} />
            break;
        case "Pakiet":
            page = <Stories stories={texts.products} showPicture={props.showPicture} />
            break;
        default:
            page = <Stories stories={texts.stories} />
    }
    return (
        <main className={styles.main}>
            {page}
        </main>
    );
}

export default main;