import React from 'react';
import Date from "../Date/Date";
import Button from '../Button/Button';
import styles from './SidebarMenu.module.css';

const sidebar = (props) => {
    let style = null;
    if (props.isDisplayed) {
        style = {transform: 'translateX(0)'}
    }
    const menuElements = [
        'O książce',
        'Fragmenty',
        'FM-owe historie',
        'Twoja historia',
        'Pakiet',
        'CM 01/02'
    ];

    const menuList = menuElements.map((el, index) => {
        if (el === props.page) {
            return <Button active key={index} type="sidebar" onClick={(e) => props.clicked(e.target)}>{el}</Button>
        } else if (el === 'Pakiet') {
            return <Button special key={index} type="sidebar" onClick={(e) => props.clicked(e.target)}>{el}</Button>
        } else {
            return <Button key={index} type="sidebar" onClick={(e) => props.clicked(e.target)}>{el}</Button>
        }
    })
    return (
        <nav className={styles.sidebar} style={style}>
            <div className={styles.toggler} onClick={props.toggleMenu}>MENU</div>
            <Date/>
            <div className={styles.arrows}>
                <Button type="arrow" onClick={props.prev}>&#8678;</Button>
                <Button type="arrow" onClick={props.next}>&#8680;</Button>
            </div>
            {menuList}
        </nav>
    );
}

export default sidebar;