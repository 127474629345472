import React from 'react';
import Gallery from '../Gallery/Gallery';
import Link from '../Link/Link';
import Button from '../Button/Button';
import styles from './FullText.module.css';

const fullText = ({text, displayForm, showPicture}) => {
    const createMarkup = (string) => {
        return {__html: string};
    }

    const paragraphs = text.text.map((paragraph, index) => <p key={index} dangerouslySetInnerHTML={createMarkup(paragraph)} />);
    let button = null;
    if (displayForm) button = <Button type="red" onClick={displayForm}>Sprawdź, czy Twoja historia trafiła do książki</Button>

    return (
        <div className={styles.text}>
            <h2>{text.title}</h2>
            <div className={styles.container}>
                {paragraphs}
                <Gallery images={text.images} showPicture={showPicture} />
            </div>
            {button}
            {text.link ? <Link type="button--red" to={text.link}>Pobierz grę</Link> : null}
        </div>
    );
}

export default fullText;