import ksiazki from '../Images/ksiazki_1000X1000.jpg';
import srodek from '../Images/mockup-srodek.jpg';
import ksiazka from '../Images/pakiet_1200X900_05ksiazka.jpg';
import kubek from '../Images/pakiet_1200X900_02kubek.jpg';
import zakladka from '../Images/pakiet_1200X900_03zakladka.jpg';
import pilka from '../Images/pakiet_1200X900_04pileczka.jpg';



const texts = {
    about: {
        title: 'O książce',
        text: [
            'Jest taka gra, którą zna każdy człowiek związany z piłką nożną. Wydawana co roku od wielu lat, zgromadziła już przed komputerami kilka pokoleń fanów futbolu i wciągnęła ich do wirtualnego świata na długie godziny. Niektórzy wygrywali dzięki niej pieniądze, inni zawalali szkołę lub pracę, a jeszcze inni słyszeli na rozprawie rozwodowej, że jest jednym z powodów rozpadu małżeństwa. Tak, to właśnie Football Manager.',
            'Dzięki książce „Football Manager to moje życie” dowiesz się, w jaki sposób powstawała gra i dlaczego Electronic Arts odesłało jej twórców z kwitkiem. Odkryjesz też sporo polskich wątków oraz poznasz piłkarzy, którzy w wirtualnym świecie byli legendami, choć w rzeczywistości nie zdołali przebić się na szczyt.',
            'To pierwsza książka w Polsce poświęcona w całości tematyce Football Managera. Momentami zaskakująca, pełna humoru i niecodziennych opowieści, które w każdym miłośniku tej gry obudzą wiele wspomnień. Interesujesz się piłką nożną i symulatorami sportowymi? Spędziłeś przed monitorem wiele godziny, rozgryzając taktykę rywali i zestawiając najlepszą jedenastkę ukochanej drużyny? To książka w sam raz dla Ciebie!'
        ],
        images: [
            {
                src: ksiazki,
                alt: 'stos książek'
            }
        ]
    },
    aboutAction: {
        title: '„Football Manager to moje życie” – Twoja historia w książce o Football Managerze (akcja zakończona)',
        text: [
            `Akcja zbierania historii związanych z Football Managerem dobiegła końca. Poznaliśmy już tych, dla
            których Football Manager to coś więcej niż tylko gra. Tych, którzy na ustalaniu taktyki i analizowaniu
            rywali spędzili tyle godzin, że bez problemów dostaliby angaż w ligowym klubie. Tych, którzy w kółko
            powtarzali rodzinie zdanie: „Jeszcze tylko jeden mecz, obiecuję!”, po czym rozgrywali całą ligową
            rundę. Podzieliliście się z nami swoimi doświadczeniami z grą w Football Managera – i w tym miejscu
            chcieliśmy podziękować wszystkim, którzy podjęli się tego wyzwania.`,
            `Na naszą skrzynkę pocztową trafiły rewelacyjne historie. Udowodniliście, że w naszym kraju
            prawdziwych maniaków najpopularniejszego symulatora piłkarskiego wszech czasów nie brakuje!
            Najlepsze opowiadania już w lutym znajdą się w polskim wydaniu „Football Manager to moje życie.
            Historia najpiękniejszej obsesji”. To będzie niezapomniana lektura.`,
            `<b>Dziękujemy wszystkim, którzy przesłali do nas swoje FM-owe historie!</b>`
        ],
        /* images: [
            {
                src: srodek,
                alt: 'przykładowe strony z książki'
            }
        ] */
    },
    aboutGame: {
        title: 'Championship Manager 01/02',
        text: [
            'Nie ma wątpliwości – ta gra to fenomen. Zagłębiając się w nią, nie doświadczymy wspaniałej szaty graficznej ani oprawy dźwiękowej, ale wszystkie tabelki, liczby i statystyki sprawiają, że bardzo łatwo jest przepaść i spędzić na rozgrywce niezliczone godziny.',
            'Kultowy Championship Manager 01/02 pochłania, uzależnia, ale przede wszystkim dostarcza mnóstwo rozrywki. Gra została wydana już prawie dwie dekady temu, ale do dziś uznaje się ją za tą, która wytyczyła nowe standardy wśród piłkarskich symulatorów. To też fantastyczna kapsuła czasu, która zabierze każdego fana futbolu w podróż do początków XXI wieku. A gdyby ktoś wolał korzystać z obecnych składów zespołów ligowych, to w sieci bez problemów znajdzie odpowiednie aktualizacje.',
            'Wydawca gry już jakiś czas temu postanowił udostępnić tę edycję za darmo. Z przyjemnością zapraszamy was zatem na stronę, z której będziecie mogli pobrać legendarnego CM-a i poczuć niezapomniany klimat symulatora, który dla wielu stał się czymś więcej niż tylko zwykłą grą.'
        ],
        link: 'https://champman0102.co.uk/downloads.php?do=file&id=2'
    },
    fragments: [
        {
            id: 'fragment-0',
            author: 'Fragment 1',
            title: 'List od Electronic Arts',
            text: [
                'Paul: Dostaliśmy list od Electronic Arts, prawda?',
                'Miles: Tak, dostaliśmy odmowny list od Electronic Arts z plamą po herbacie, którą zostawił Oliver.',
                'Paul: Swoją drogą, wiesz gdzie jest ten list?',
                'Miles: Tak, wiem gdzie mamy wszystkie skany. Duffy trzyma je schowane. Oryginalny podręcznik użytkownika też gdzieś jest.',
                'Oliver: Myślę, że wysłałem ten list do około dwudziestu wydawców i tylko dwóch albo trzech odpisało. EA napisał coś w stylu "Nie ma grafiki, a musi próbować podjąć rywalizację z obecnymi grami. Naszym zdaniem to nie zadziała, ale dlaczego nie spróbujecie zareklamować się w jakimś czasopiśmie?". W porządku, taką mieli opinię.',
                'Miles: Przynajmniej dali nam poradę.',
                'Paul: Tak jest.',
                'Oliver: Dokładnie. Nie zignorowali nas jak większość. Potem zainteresował się nami Domark, który później oczywiście stał się Eidosem.',
                'Paul: Thalamus.',
                'Oliver: I Thalamus. Mieliśmy zagwozdkę, trudny wybór pomiędzy tą dwójką. Z jednej strony Thalamus, który wydawał się być prowadzony przez jednego gościa. Naprawdę fajny koleś, pomógł nam i złożył uczciwą ofertę. Z drugiej strony Domark. Trochę po cwaniacku rozgrywaliśmy ich w nasz naiwny sposób, myśląc, że jesteśmy bardzo sprytni.',
                'Paul: Ostatecznie ubiliśmy śmieciowy interes.',
                'Oliver: Tak, to była beznadziejna umowa.',
                'Miles: To zdarzyło się zanim do was dołączyłem.',
                'Paul: Pokazaliśmy im grę nazwaną European Champions. Masz jej papiery?',
                'Oliver: Są gdzieś, myślę, że na okładce podręcznika użytkownika było zdjęcie Bryana Robsona celebrującego gola w koszulce reprezentacji Anglii. Kiedy to było... Wiem, że grali z Holandią, ale nie w Holandii.',
                'Paul: 1988.',
                'Oliver: Euro. Tak. 1988.',
                'Paul: Więc pracowaliśmy nad nią pięć lat, z różną intensywnością. Myślę, że można uczciwie powiedzieć, że Oliver włożył mnóstwo pracy w dokumentację i rozsyłanie gry. To ty wpadłeś na pomysł, że coś z tym moglibyśmy zrobić, zamiast tylko grać z kumplami w soboty, prawda? Graliśmy pod mecze w telewizji.'
            ]
        },
        {
            id: 'fragment-1',
            author: 'Fragment 2',
            title: 'Z FMem na kozetce',
            text: [
                'Iain Macintosh: Dzień dobry, panie doktorze. Dziękuję, że tak szybko znalazł pan dla mnie czas.',
                'Simon Moore: Żaden problem, Iain.',
                'IM: Widzi doktor… Nie powinienem się przypadkiem położyć na kozetce?',
                'SM: Jeśli tak wolisz.',
                'IM: Chyba tak zrobię. (Kładzie się). O tak, od razu lepiej.',
                'SM: Z jakim problemem do mnie przychodzisz?',
                `IM: Więc, doktorze, chodzi o to, że od 20 lat gram w Football Managera. Od pierwszej wersji, tej z wizerunkiem wściekłego faceta na pudełku. Spędziłem dziesiątki, setki, tysiące godzin, zarządzając
                fikcyjnymi drużynami, ustalając im taktykę, szukając i rekrutując do nich zawodników. Kiedy myślę o
                tym, co mogłem osiągnąć w zamian, ilu języków mogłem się nauczyć, ile miejsc zobaczyć, pęka mi
                serce. W pewnym momencie, gdy będę leżał na łożu śmierci, otoczony przez bliskich, łagodnie
                odpływając na następny plan egzystencji, będę potrafił myśleć tylko o tym, że musiałem spędzić,
                łącznie, około sześciu nieprzerwanych choćby na sekundę miesięcy przy grze komputerowej. Ale wie
                doktor, co jest najgorsze?`,
                'SM: Dalej, proszę…',
                `IM: Że jestem dziennikarzem sportowym. Mam akredytację i tak dalej. Jestem co prawda zależny od
                pewnego budżetu i zdrowego rozsądku, ale generalnie mogę oglądać, jaki tylko mecz piłkarski sobie
                zamarzę i gdzie tylko sobie zamarzę, a jeszcze mi za to zapłacą. Więc zasadniczo spędzam cały wolny
                czas, robiąc coś, co jest w sumie przedłużeniem mojej pracy. Czy to nie dziwne?`,
                'SM: Eeech…',
                'IM: O Boże, to nie najlepszy początek.',
                'SM: Ale nie grasz wciąż w tę samą grę? Tę ze wściekłym facetem na pudełku?',
                'IM: Dobry Jezu, nie. Kupowałem każdą kolejną wersję, gdy tylko trafiała na rynek. Nie jestem zakleszczony w 1992 roku.',
                'SM: Ale lubisz mikrozarządzanie?',
                `IM: O tak. Uwielbiam przejmować drużynę i ustalać nie tylko skład, ale też sztab szkoleniowy, plan
                treningów, kto ze starszyzny ma być mentorem których juniorów, wysilać spryt nad stałymi
                fragmentami, wysyłać w świat skautów – wszystkie te rzeczy. I nigdy nie gram tylko pierwszą drużyną.
                Zawsze prowadzę też rezerwy i zespoły juniorskie, tylko po to, by mieć pewność, że w klubie na
                wszystkich szczeblach następuje progres. I teraz najbardziej zastanawiająca rzecz: nie jestem w ogóle
                poukładany w jakiejkolwiek innej dziedzinie życia. Moje kwity podatkowe leżą wszędzie. Jeśli muszę
                coś zapisać, robię to długopisem na ręce, bo zawsze gubię notatniki. Ale gdy zanurzam się w świat
                Football Managera, nagle staję się najbardziej skrupulatnym człowiekiem na ziemi. Wszystko jest
                nakreślone, przygotowane, a przyszłość zaplanowana.`,
                'SM: Jak się czujesz, gdy grasz?',
                'IM: Szczerze?',
                'SM: Szczerze.',
                'IM: Jak sam Bóg.',
                'SM: Naprawdę?',
                `IM: Czuję się, jak pan Bóg siedzący na zboczu góry, spoglądający w dół na śmiertelników, małych i
                zagonionych jak mrówki, którzy desperacko starają się wypełnić moje żądania, przerażonych na myśl
                o moim gniewie. Czy to dziwne? To dość dziwne, prawda?`
            ]
        },
        {
            id: 'fragment-2',
            author: 'Fragment 3',
            title: 'Maszyna czasu Football Managera',
            text: [
                `Witaj w maszynie czasu Football Managera! Niewiele wiemy o nauce, ale wydaje nam się, że dzięki
                dwóm bateriom AA i przewodom rozruchowym za tobą zabierzemy cię do o wiele prostszych
                czasów. Czasów, kiedy Lionel Messi mógł dominować jedynie w meczach, jakie rozgrywał podczas
                przyjęcia z okazji swoich czternastych urodzin. Czasy, kiedy petrodolary w kontekście Manchesteru
                City oznaczały pieniądze na paliwo do autokaru. Czasy, kiedy Roman Abramowicz miał znacznie
                lepsze pomysły na wydawanie forsy. To czasy Championship Managera 2001/02. Manchester United
                właśnie sięgnął po trzeci kolejny tytuł, ale sir Alex Ferguson planuje skończyć karierę na koniec
                sezonu.`,
                `Na włoskim podwórku rządzi Roma, w Hiszpanii powodzi się Realowi, podczas gdy Barcelona jest
                cieniem swojej dawnej chwały. Katalończycy ledwo zakwalifikowali się do Ligi Mistrzów! Czy obudzą
                się z marazmu? Zapraszam, wejdź do kapsuły, szybko, zakryj oczy. Czasami trochę trzęsie. A, i nie
                martw się, jeśli trochę popuścisz, to zupełnie normalne…`,
                `2002`,
                `Niech żyje Portugalia i jej złota generacja! Dzięki bramkom Luísa Figo, João Pinto i Rui Costy
                Portugalczycy pokonali Włochy w rozgrywanym w Tokio finale mistrzostw świata i położyli
                fundamenty pod erę swojej dominacji. Anglia, dla odmiany, została pokonana w fazie pucharowej.
                Przez Niemców. Po karnych. Znowu.`,
                `W Premier League sir Alex Ferguson odchodzi na emeryturę z wykopem – pokonuje Romę w finale
                Ligi Mistrzów i sięga po czwarty kolejny tytuł Premier League, zatrzymując się po drodze tylko po to,
                by nakrzyczeć na chmurę 3 i dać szlaban dziennikarzowi z hrabstwa Lancashire. Sir Alex kończy
                karierę, by spędzać więcej czasu ze swoimi końmi, a zastępuje go boss Leeds, David O’Leary. United
                Fergiego wygrywa ligę z 10 punktami przewagi nad Arsenalem, głównie dzięki swojemu nowemu
                gwiazdorowi, Ruudowi Van Nistelrooyowi, który zanotował niesamowity bilans: zdobył 42 dwie
                bramki w 54 meczach. Arsenal, którego sezon zrujnowała zdrada Patricka Vieiry (Barcelona kupiła go
                za 38 milionów funtów), musi się pocieszyć Pucharem Anglii. Nikogo nie zaskakuje, gdy Arsène
                Wenger decyduje się zachomikować pieniądze uzyskane za Vieirę, tłumacząc, że to „na czarną
                godzinę”. W tym czasie młody napastnik Tonton Zola Moukoko wdziera się przebojem do składu
                Derby, strzelając siedem bramek w 12 meczach. Kenny Dalglish ratuje Charlton przed spadkiem, a
                ligę niżej leci Southampton.`,
                `Jeśli Vieira odszedł w pogoni za tytułami, to czekało go rozczarowanie. Pomimo 32 bramek Patricka
                Kluiverta to Real, wzmocniony kupionym w styczniu za 37 milionów funtów Francesco Tottim,
                wygrywa ligę o punkt. We Włoszech rozgromieni w Lidze Mistrzów nieudacznicy z Romy
                przynajmniej dają radę wyprzedzić Milan w wyścigu o scudetto, głównie dzięki 66 bramkom duetu
                Gabriel Batistuta i Vincenzo Montella. Tymczasem w Szkocji zostaje mistrzem, drudzy są Rangersi.`,
                `(...)`,
                `2010`,
                `Xisco z Sevilli zostaje królem świata. Jego gole pieczętują pierwszy w historii tytuł mistrza świata dla
                Hiszpanii, a wszystko po nerwowym finale przeciwko Francji prowadzonej przez Paula Le Guena.
                Anglia Dave’a Jonesa – oczywiście – zostaje wyrzucona w ćwierćfinałach, co i tak jest najlepszym jej
                rezultatem na mistrzostwach świata od czasów poczynań kadry Bobby’ego Robsona w 1990 roku.`,
                `Po raz pierwszy od 1927 roku Newcastle sięga po tytuł. Menedżer Louis van Gaal świętuje dwa dni, a
                potem na jednej nodze pędzi do Deportivo. U bukmacherów nie można obstawiać nagrody piłkarza
                roku: jest jasne, że niesamowite 50 bramek Cherno Samby w 65 meczach Srok sprawią, że nigdy
                więcej nie będzie musiał płacić za piwo na Tyneside. Liverpool, który wyrywa Ronaldinho z PSG,
                zostaje wicemistrzem. Arsenal i Leeds są – odpowiednio – trzecie i czwarte. Menadżer Chelsea,
                Tommy Svensson, zostaje wyrzucony po zaledwie sześciu miesiącach i zastąpiony – zgadliście –
                Kennym Dalglishem. The Blues kończą na szóstym miejscu, a koszmarny Manchester United Davida
                O’Leary’ego jest ósmy. Grimsby pod Peterem Taylorem finiszuje dziewiąte, ale Wrexham pakuje się i
                wraca do drugiej ligi, zdobywając przez cały sezon zaledwie 16 punktów. Manchester City spada na
                trzeci poziom rozgrywkowy, gdzie spotka Southampton, który wygrał czwartą ligę.`,
                `W Europie Dortmund przynosi zgubę Juventusowi, który drugi raz z rzędu dociera do finału Ligi
                Mistrzów. Stara Dama znajduje pocieszenie w Italii, gdzie zdobywa scudetto i przełamuje 10-letnie
                panowanie rzymian. Lazio jest trzecie, Roma piąta. Fabio Capello spycha policyjny wóz ze schodów w
                centrum miasta, po czym ucieka, twierdząc, że zrobiła to grupa dresiarzy. Później zostaje zwolniony z
                posady.`,
                `W Hiszpanii Real ma poważne kłopoty. Najstarsi górale nie pamiętają, kiedy ostatnio przegrał więcej
                meczów, niż wygrał. Kończy na ósmym miejscu, ale jakimś cudem Vicente del Bosque zachowuje
                posadę. W Barcelonie Carles Rexach zapewnia sobie dożywotni kontrakt, tracąc zaledwie 11 oczek
                przez cały sezon, finiszując 35 punktów przed Valencią. Tymczasem w Szkocji Celtic zostaje mistrzem,
                drudzy są Rangersi. Uff.`
            ]
        }
    ],
    stories: [
        {
            id: 'story-0',
            author: 'Sebastian Mila',
            title: 'Sentyment do Football Managera',
            text: [
                'Najwięcej sentymentu mam jednak do Football Managera, ale tego klasycznego, najstarszego, katowanego przeze mnie na commodorze: cztery ligi angielskie i urywki meczów. Mogłeś wykupywać sponsorów różnego rodzaju, prowadziłeś negocjacje, a proste grafiki meczowe okazywały się niesłychanie emocjonujące. Nie wiem, jak to zrobili, ale sytuacje były zawsze unikalne. Dla mnie to był kolejny fundament pasji do futbolu.',
                'Później przesiadłem się na starego Championship Managera, tego z samymi napisami. Najczęściej grałem holenderską Barceloną z Reizigerem, Kluivertem, Zendenem i Overmarsem albo Interem Mediolan z Ronaldo i Vierim. Do każdej drużyny kupowałem Djalminhę z Deportivo. Zapomniany geniusz, Il Mago, bajeczny zarówno w CM-ie, jak i w realu.',
                'W Polsce zawsze wybierałem Orlen Płock, bo… akurat wtedy tam grałem. Mieliśmy mocny jak na drugą ligę skład: Nosal, Geworgian, Romuzga, Sobolewski, Soczewka, Krupski w bramce. Ja też byłem dobry, miałem w grze takie parametry, że po kilku latach brali mnie na Zachód. Dokupowałem Piątkowskiego, Harasimowicza i Świętochowskiego, a potem patrzyłem, jak walą bramkę za bramką. Kwestią czasu było mistrzostwo i walka w Lidze Mistrzów. Od CM-a nie mogłem się oderwać. Każdy, kto grał w menedżera, zna syndrom jeszcze jednego, ostatniego meczu. Jeszcze jeden, tylko jeden, a nim się obejrzysz, masz za sobą pół sezonu. Robiłem też save’y przy najważniejszych meczach. Nie nagminnie, ale zdarzało się. Nie ma się czym chwalić, ale to jak w „Samych swoich”: „Sąd sądem, a sprawiedliwość musi być po naszej stronie”. Zdarzało się, że szedłem na skróty i dawałem sobie drugą szansę, gdy widziałem, że mogę nie awansować do Ekstraklasy lub przegrać decydujący mecz w Lidze Mistrzów.',
                'Bywało, że razem z Ernestem, moim najlepszym przyjacielem z lat dzieciństwa, zarywaliśmy noce przy menedżerach. Nie miałem wtedy jeszcze komputera, ale jeśli rodzice akurat gdzieś pojechali i był wolny dom, to nieśliśmy wszystko przez osiedle: monitor, komputer, cały sprzęt i nocował u mnie. Graliśmy całą noc, razem jednym klubem: dogadywaliśmy się, że jeden ustawia zespół, a drugi jest od transferów, później ten od taktyki, ten od doboru zawodników. Fantastycznie to wspominam. Jak zwyciężaliśmy, to razem, ciesząc się, skacząc, tak jakby wygrał prawdziwy zespół.'
            ]
        },
        {
            id: 'story-1',
            author: 'Leszek Milewski',
            title: 'W CM-a w mojej szkole łupałem tylko ja',
            text: [
                'Moje pierwsze zetknięcie z serią Championship Manager to końcówka lat dziewięćdziesiątych, dopiero co oduczyłem się mówić na chleb „bep”, ale jeszcze wchodzę na stojąco pod stół. Siedzę u kuzyna, do którego swego czasu przyjeżdżałem, żeby całymi dniami grać w Sensible Soccer; ma nową piłkarską gierkę, odpala, a ja patrzę, patrzę, tyle że piłki jakoś nie widać.',
                '- A kiedy zacznie się mecz?',
                '- Już trwa.',
                '- Te napisy?',
                '- Te napisy.',
                'Dla dzieciaka to było niepojęte, że tekst ma odpowiadać wolejom, rabonom i karniakom, ale z każdym kolejnym golem, każdym kolejnym transferem, każdym mistrzostwem zaczynałem rozumieć ten świat i doceniać jego głębię. Zrozumiałem też, że brak graficznego meczu nie jest żadną przeszkodą, a koniecznym kompromisem, który jednak daje w zamian furę możliwości.',
                'Gdy jakiś czas później dorobiłem się swojego komputera, kupionego w dużej mierze za moje komunijne pieniądze, jako jedną z pierwszych gier zamówiłem CM-a 01/02. Oczywiście zamówiłem u kolegi pirata, który miał nagrywarkę – nie będę was ściemniał, takie były czasy. Po jakimś czasie CM przyszedł, ja wyłożyłem dyszkę, a kolega nie zapomniał okrasić transakcji pytaniem:',
                '- Lechu, a gdzie tam się włącza mecz?',
                '- Te napisy to mecz.',
                '- Napisy?',
                '- Tak.',
                '- I ty w to grasz?',
                '- Tak.',
                'On w śmiech. To była dla wielu bariera nie do przeskoczenia – w zasadzie w CM-a w mojej szkole łupałem tylko ja. Raz próbowałem zarazić nim kumpla, ale wolał pozostać przy znacznie prostszej Lidze Polskiej Manager.'
            ]
        },
        {
            id: 'story-2',
            author: 'Michał Leniec',
            title: 'Z Football Managera do Księgi Rekordów Guinnessa',
            text: [
                'Nigdy bym nie pomyślał, że granie w Football Managera sprawi, że kiedyś znajdę się w Księdze rekordów Guinnessa. Doprowadziła do tego skomplikowana historia. Korzystając z Twittera, oczywiście obserwowałem profil gry. Pewnego dnia podali tam, że jakiś Anglik rozegrał rekordową liczbę 154 sezonów i został wpisany do Księgi. Trafiłem na wywiad z tym gościem, w którym powiedział, że swojego wyniku już nie pobije, bo padł mu save.',
                'Popatrzyłem na jego rekord i akurat tak się złożyło, że w grze, w którą grałem, brakowało mi do jego osiągnięcia może 15 sezonów. Dla mnie to miesiąc grania. „Kurczę, tak niewiele mi brakuje, to sobie pogram” – pomyślałem. Tak zrobiłem, po czym zgłosiłem się do Księgi Rekordów Guinnessa. Wypełniłem formularz, przesłałem zrzuty ekranu z gry i czekałem. Na podjęcie decyzji mieli dwa miesiące. Odpowiedź przyszła, kiedy byłem na wakacjach. Napisali, że rekord nie został uwzględniony.',
                '„Dobra, olać to” – stwierdziłem, ale w listopadzie ogłosili nowego rekordzistę. Wtedy postanowiłem odezwać się publicznie na Twitterze. Okazało się, że ma on dużą moc, bez niego nic bym nie załatwił. Napisałem, że przecież pobiłem ten rekord wcześniej. Wiele osób mnie poparło, twierdząc, że to wstyd, że mój wynik nie został uznany. Dosłownie w ciągu kilku godzin skontaktował się ze mną pan z Księgi rekordów Guinnessa z pytaniem, czy to prawda. Przesłałem save’a i już na drugi dzień dostałem informację, że rekord został uznany.',
                'Certyfikat za 221 rozegranych sezonów dostałem pocztą i w sumie do dziś nigdzie go sobie nie powiesiłem. Kiedyś na pewno to zrobię, chociaż rekordzistą już nie jestem. Ale przecież każdy mistrz kiedyś musi przestać być mistrzem.'
            ]
        }
    ],
    products: [
        {
            id: 'product-0',
            author: 'Książka',
            title: 'Football Manager to moje życie. Historia najpiękniejszej obsesji',
            text: [
                'Wyjątkowa, pierwsza w Polsce książka o najpopularniejszym symulatorze piłkarskim w historii, a w niej pełno ciekawostek, anegdot i historii o początkach gry czy legendach Football Managera, które w rzeczywistym świecie piłki nie poradziły sobie aż tak dobrze. Książka została też uzupełniona o wiele polskich wątków!'
            ],
            images: [
                {
                    src: ksiazka,
                    alt: 'książka'
                }
            ]
        },
        {
            id: 'product-1',
            author: 'Gadżet 1',
            title: 'Kubek prawdziwego managera',
            text: [
                'Zarwałeś nockę, grając w Football Managera? Analizowałeś taktykę rywali swojej drużyny do późnych godzin i wolałbyś, żeby nikt nie pytał cię dlaczego ledwo żyjesz? Ten kubek w połączeniu z aromatyczną kawą lub mocną herbatą postawi cię na nogi. A wieczorem postawisz go obok siebie na biurku i jeszcze raz zasiądziesz przed komputerem, by rozegrać kolejny sezon.'
            ],
            images: [
                {
                    src: kubek,
                    alt: 'kubek'
                }
            ]
        },
        {
            id: 'product-2',
            author: 'Gadżet 2',
            title: 'Zakładka do save\'owania książki',
            text: [
                'Jesteśmy pewni, że po książkę o Football Managerze wielokrotnie będziesz sięgał z przyjemnością. To właśnie dlatego przygotowaliśmy specjalną zakładkę, która pozwoli ci bez problemów powrócić do momentu, w którym skończyłeś lekturę.'
            ],
            images: [
                {
                    src: zakladka,
                    alt: 'zakładka'
                }
            ]
        },
        {
            id: 'product-3',
            author: 'Gadżet 3',
            title: 'Piłeczka antystresowa',
            text: [
                'Przed twoim klubem w Football Managerze walka o awans do najwyższej klasy rozgrywkowej? Czekasz na mecz decydujący o mistrzostwie kraju? A może już za moment rozegrasz finał Ligi Mistrzów? Przygotuj się dobrze do tego spotkania. Dzięki piłeczce antystresowej poradzisz sobie nawet z najbardziej nerwową sytuacją na wirtualnym boisku.'
            ],
            images: [
                {
                    src: pilka,
                    alt: 'piłeczka'
                }
            ]
        }
    ]
}

export default texts;